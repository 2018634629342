import { Callout, BreakpointHelpers } from '@sh24/ui-components'
import { format, subDays } from 'date-fns'
import styled from 'styled-components'
import useTranslations from '../../../utils/use-translations'
import { camelize } from '../../../utils/helpers'

type OrderType = {
  orderState: string;
  updatedAt: Date;
}

type StatusContainerTypes = {
  lastOrders: {
    prepOrder: OrderType | null,
    stiTestOrder: OrderType | null,
  } | null
}

const { mobileOnly, tabletUp } = BreakpointHelpers

const Container = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme?.spacing?.md};
    display: flex;
    height: auto;

    ${mobileOnly(`
      flex-direction: column;
      gap: ${theme?.spacing?.sm};
    `)}

    ${tabletUp(`
        flex-direction: row;
        justify-content: center;
        gap: ${theme?.spacing?.md};

        > div {
          width: 40%;
        }
      `)}
  `}
`

const moreThanSevenDaysOld = (updatedAt: Date) => updatedAt < subDays(new Date(), 7)

const renderTranslation = (content: string,
  orderType: string,
  { orderState, updatedAt }: OrderType) => {
  const stateCamelized = camelize(orderState)
  if (orderType === 'stiTestOrderStatusCard' && orderState === 'dispatched' && moreThanSevenDaysOld(updatedAt)) {
    return `prepDashboard.stiTestOrderStatusCard.${content}.returnKitReminder`
  }
  return `prepDashboard.${orderType}.${content}.${stateCamelized}`
}

const renderStatusCard = (orderType: string, order: OrderType) => {
  const translations = useTranslations()
  const calloutCard = (
    <Callout
      key={orderType}
      preTitle={format(order.updatedAt, 'dd MMMM HH:mm')}
      backgroundColour="/special200"
      iconBackgroundColour="/special300"
      icon="bell"
      title={translations[renderTranslation('title', `${orderType}StatusCard`, order)]}
      content={<p>{translations[renderTranslation('description', `${orderType}StatusCard`, order)]}</p>}
    />
  )

  return calloutCard
}

const StatusContainer = ({ lastOrders }: StatusContainerTypes) => {
  const renderStiTestOrderStatusCard = (stiTestOrder: OrderType | null) => {
    if (stiTestOrder == null || stiTestOrder.orderState === 'refused') {
      return null
    }

    if (stiTestOrder.orderState === 'notified' && moreThanSevenDaysOld(stiTestOrder.updatedAt)) {
      return null
    }

    return renderStatusCard('stiTestOrder', stiTestOrder)
  }

  const renderPrepOrderStatusCard = (prepOrder: OrderType | null) => {
    if (prepOrder == null) {
      return null
    }

    if (prepOrder.orderState === 'dispatched' && moreThanSevenDaysOld(prepOrder.updatedAt)) {
      return null
    }

    return renderStatusCard('prepOrder', prepOrder)
  }

  return (
    <Container>
      {lastOrders && renderStiTestOrderStatusCard(lastOrders.stiTestOrder)}
      {lastOrders && renderPrepOrderStatusCard(lastOrders.prepOrder)}
    </Container>
  )
}

export default StatusContainer
