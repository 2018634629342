import { useContext } from 'react'
import styled from 'styled-components'
import AccountContext from '../../../../contexts/account-context'
import ensureOrdersLoaded from '../../../../utils/ensure-orders-loaded'
import useTranslations from '../../../../utils/use-translations'
import OrdersHistoryDisplay from '../../../OrderHistoryDisplay/orders-history-display'

const Centered = styled.div`
  text-align: center;
`

const OrdersHistoryModule = () => {
  const { ordersContext } = useContext(AccountContext)
  const { orders, errors } = ordersContext
  ensureOrdersLoaded()

  const translations = useTranslations()

  if (errors?.errors) {
    return (
      <>
        <h2>An error occurred</h2>
        <p>
          There was a problem displaying your orders. This has been reported
          and will be looked in to shortly. Please come back and try again
          later.
        </p>
      </>
    )
  }

  if (!orders) {
    return (
      <Centered>
        <p>{translations['orderCard.loadingOrders']}</p>
      </Centered>
    )
  }

  if (orders.orders.length === 0) {
    return (
      <Centered>
        <p>{translations['orderCard.noOrders']}</p>
      </Centered>
    )
  }

  return (
    <div className="row row-centered">
      <div className="col col-12 col-md-6 col-lg-4">
        <OrdersHistoryDisplay
          orders={orders}
        />
      </div>
    </div>
  )
}

export default OrdersHistoryModule
