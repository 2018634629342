import { format } from 'date-fns'
import { HistoricalOrder, HistoricalOrdersData } from '@sh24/admin-api-js'
import {
  ByYearAndDate, OrderCard, Tag, ButtonLink,
} from '@sh24/ui-components'
import styled from 'styled-components'
import useTranslations from '../../utils/use-translations'
import groupBy from '../../utils/group-by'
import InlineLink from '../InlineLink/inline-link'

const OrderSummaryTag = styled(Tag)`
  ${({ theme }) => `
    margin-top: ${theme?.spacing?.sm};
    margin-right: ${theme?.spacing?.sm};
    margin-bottom: ${theme?.spacing?.sm};
  `}
`

const iconMap = {
  sti_test: 'swab-urine',
}

const prepareTestData = (order: HistoricalOrder) => {
  if (!order.tests) return []

  const parsedTests = order
    .tests
    .map((test) => {
      const [type, site] = test.split('_')

      if (type === 'hepatitis') return { type: `${type}${site.toUpperCase()}` }

      return {
        type: ['chlamydia', 'gonorrhoea'].includes(type) ? 'chlamydiaAndGonorrhoea' : type,
        ...(['chlamydia', 'gonorrhoea', 'syphilis'].includes(type) ? { site } : {}),
      }
    })

  const groupedTests = groupBy<{ type: string, site?: string }, string>(parsedTests, (test) => test.type)
  return Array.from(
    groupedTests,
    ([key, value]) => (
      {
        type: key,
        sites: Array.from(new Set(value.map((row) => row.site))),
      }
    ),
  )
}

const ViewResultsButton = ({ text, url }: {text: string, url: string}) => (
  <ButtonLink variation="primary" fullWidth iconPosition={null}>
    <InlineLink
      text={text}
      url={url}
    />
  </ButtonLink>
)

const OrdersHistoryDisplay = ({ orders }: { orders: HistoricalOrdersData }) => {
  const translations = useTranslations()

  const transformTests = (order: HistoricalOrder) => (
    prepareTestData(order).map(
      ({ type, sites }) => (
        {
          title: translations[`orderCard.stiTest.${type}`] || type,
          description: sites.map((site) => translations[`orderCard.stiTest.${site}`] || site).join(', '),
        }
      ),
    )
  )

  const renderOrderCard = (order: HistoricalOrder) => {
    const viewResultsButton = ['resultsReady', 'resultsHeld', 'resultsUpdated'].includes(order.orderState) ? (
      <ViewResultsButton
        text={translations[`orderCard.viewResultsButtonText.${order.orderState}`]}
        url={`/account/results/${order.sh24Uid}`}
      />
    ) : null

    return (
      <OrderCard
        tagText={translations['orderCard.stiTest']}
        tagIcon={iconMap[order.orderType]}
        items={transformTests(order)}
        orderId={`${translations['orderCard.orderId']} ${order.sh24Uid}`}
        status={`${translations[`orderCard.${order.orderState}`] || order.orderState} ${format(order.updatedAt, 'dd LLLL yyyy')}`}
        bottomSectionLower={viewResultsButton}
      />
    )
  }

  const ordersDisplay = orders.orders
    .sort(({ createdAt: a }, { createdAt: b }) => b.valueOf() - a.valueOf())
    .map((order, index) => (
      {
        date: order.createdAt,
        content: renderOrderCard(order),
        previewContent: (
          <OrderSummaryTag
            key={order.sh24Uid}
            text={translations['orderCard.stiTest']}
            icon={iconMap[order.orderType]}
          />
        ),
        defaultOpen: index === 0,
      }
    ))

  return (
    <ByYearAndDate items={ordersDisplay} />
  )
}

export default OrdersHistoryDisplay
